<template>
  <div >
    <vue-element-loading
      :active="storage"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FF6907"
      spinner="bar-fade-scale"
    >
      <img src="../../assets/loading.gif" alt="Loading..." />
      <!-- <v-img src="../assets/loading.gif" alt="logo" height="200px" contain /> -->
    </vue-element-loading>
  </div>
</template>
<script>
export default {
  props: ["storage", "contain"],
};
</script>