<template>
  <v-app ref="scrollContainer">
    <Haeder
      @stepper="winstepper"
      :color="header_color"
      v-if="$vuetify.breakpoint.name != 'xs'"
    />
    <Haederxs v-if="$vuetify.breakpoint.name == 'xs'" />
    <!-- <v-main> -->
    <router-view :value ="scroll_to" v-scroll="handleScroll"> </router-view>
    <!-- </v-main> -->
    <Footer />
  </v-app>
</template>
<script>
// import HelloWorld from "./components/Home/HomE";
import Haeder from "./components/Common/HeadeR";
import Haederxs from "./components/Common/HeaderXs";

import Footer from "./components/Common/FooteR";

import "./assets/StyleS.css";
export default {
  name: "App",

  components: {
    // HelloWorld,
    Haederxs,
    Haeder,
    Footer,
  },
  data() {
    return {
      items: [], // Your web page content items
      scrollPosition: 0,
      lastScrollPosition: 0,
      header_color: "transparent",
      scroll_to: false,
    };
  },
  

  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },

  methods: {
    handleScroll() {
      if (this.scrollPosition > 100) {
        this.header_color = "white";
      } else {
        this.header_color = "transparent";
      }
    },

    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    // passing the boolean for scrolling down to the page
    winstepper(data) {
      this.scroll_to = data.scroll_to;
    },
  },
};
</script>
