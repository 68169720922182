var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app-bar',{staticStyle:{"z-index":"100000"},attrs:{"flat":"","app":"","color":"white"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('a',{attrs:{"href":"/"}},[_c('v-img',{attrs:{"width":"100px","contain":"","src":require("../../assets/Images/logo-2.png")}})],1)])],1),_c('v-spacer'),(!_vm.drawer)?_c('v-icon',{staticClass:"hidden-sm-and-up",attrs:{"color":"#004DA9"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_vm._v(" mdi-menu ")]):_vm._e(),(_vm.drawer)?_c('v-icon',{attrs:{"color":"#004DA9"}},[_vm._v(" mdi-close ")]):_vm._e()],1),_c('v-navigation-drawer',{staticClass:"hiddensm-and-up",staticStyle:{"z-index":"1000"},attrs:{"floating":"","width":"100%","height":"100%","fixed":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-layout',{attrs:{"wrap":"","fill-height":""}},[_c('v-flex',{staticStyle:{"margin-top":"80px"},attrs:{"xs12":""}},[_c('v-layout',{staticClass:"fw-600",attrs:{"px-5":"","wrap":"","justify-center":"","text-left":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('a',{attrs:{"href":"/"}},[_c('span',{style:(_vm.$route.fullPath == '/' ? 'color: #004da9' : 'color: #565656')},[_vm._v(" HOME ")])])]),_c('v-flex',{attrs:{"xs12":"","align-self-center":"","py-3":""}},[_c('v-divider')],1),_c('v-flex',{attrs:{"xs12":""}},[_c('router-link',{attrs:{"to":'/aboutus'}},[_c('span',{style:(_vm.$route.fullPath == '/aboutus'
                    ? 'color: #004da9'
                    : 'color: #565656')},[_vm._v(" ABOUT US ")])])],1),_c('v-flex',{attrs:{"xs12":"","align-self-center":"","py-3":""}},[_c('v-divider')],1),_c('v-flex',{attrs:{"xs12":""}},[_c('router-link',{attrs:{"to":'/products'}},[_c('span',{style:(_vm.$route.fullPath == '/products'
                    ? 'color: #004da9'
                    : 'color: #565656')},[_vm._v(" PRODUCTS ")])])],1),_c('v-flex',{attrs:{"xs12":"","align-self-center":"","py-3":""}},[_c('v-divider')],1),_c('v-flex',{attrs:{"xs12":""}},[_c('router-link',{attrs:{"to":'/aboutus#contact'}},[_c('span',{style:(_vm.$route.fullPath == '/aboutus#contact'
                    ? 'color: #004da9'
                    : 'color: #565656')},[_vm._v(" CONTACT US ")])])],1),_c('v-flex',{attrs:{"xs12":"","align-self-center":"","py-3":""}},[_c('v-divider')],1),_c('v-flex',{attrs:{"xs12":""}},[_c('router-link',{attrs:{"to":'/prices'}},[_c('span',{style:(_vm.$route.path == '/prices'
                    ? 'color: #004da9'
                    : 'color: #565656')},[_vm._v(" PRICES ")])])],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","align-self-end":""}},[_c('v-layout',{staticStyle:{"background-color":"white"},attrs:{"wrap":"","py-5":"","justify-space-around":"","justify-center":"","text-center":""}},[_c('v-flex',[_c('a',{attrs:{"href":"mailto:customercare@slots.com.kw","target":"blank"}},[_c('v-icon',[_vm._v(" mdi-email ")])],1)]),_c('v-flex',[_c('a',{attrs:{"href":"https://www.instagram.com/slotsco/?igshid=MzRlODBiNWFlZA%3D%3D","target":"blank"}},[_c('v-icon',[_vm._v(" mdi-instagram ")])],1)]),_c('v-flex',[_c('a',{attrs:{"href":"https://www.linkedin.com/company/slotskw/","target":"blank"}},[_c('v-icon',[_vm._v(" mdi-linkedin ")])],1)])],1),_c('v-layout',{attrs:{"wrap":"","pt-3":""}},[_c('v-flex',{attrs:{"xs12":"","pa-1":""}},[_c('v-btn',{staticClass:"bookBTn",attrs:{"elevation":"0","block":_vm.$vuetify.breakpoint.name == 'xs',"dark":"","color":"#004DA9","to":"/demobooking"}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(" Book Demo ")])])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }