<template>
  <div>
    <v-app-bar flat app style="z-index: 1000000000" :color="color">
      <v-app-bar-nav-icon
        class="hidden-sm-and-up"
        variant="text"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-layout wrap justify-center class="hidden-xs-only">
        <v-flex lg11 px-lg-14>
          <v-layout>
            <v-flex sm3 text-left>
              <v-layout wrap justify-start align-start>
                <v-flex xs3 lg4 shrink align-start>
                  <a href="/">
                    <v-img
                      style="margin: 0 !important"
                      height="60px"
                      class="ml-0"
                      contain
                      src="../../assets/Images/logo-2.png"
                    >
                    </v-img>
                  </a>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex sm6 align-self-center>
              <v-layout wrap justify-space-around text-center class="text">
                <v-flex align-self-center xs3 class="content">
                  <router-link :to="'/'">
                    <span
                      :style="
                        $route.path == '/' ? 'color: #004da9' : 'color: #565656'
                      "
                    >
                      Home
                    </span>
                  </router-link>
                </v-flex>
                <v-flex align-self-center xs3 class="content">
                  <router-link :to="'/aboutus'">
                    <span
                      :style="
                        $route.path == '/aboutus'
                          ? 'color: #004da9'
                          : 'color: #565656'
                      "
                    >
                      About Us</span
                    >
                  </router-link>
                </v-flex>
                <v-flex align-self-center xs3 class="content">
                  <router-link :to="'/products'">
                    <span
                      :style="
                        $route.path == '/products'
                          ? 'color: #004da9'
                          : 'color: #565656'
                      "
                    >
                      Products
                    </span>
                  </router-link>
                </v-flex>
                <v-flex align-self-center xs3 class="content">
                  <router-link :to="'/prices'">
                    <span
                      :style="
                        $route.path == '/prices'
                          ? 'color: #004da9'
                          : 'color: #565656'
                      "
                    >
                      Prices
                    </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex sm3 text-right align-self-center>
              <v-btn
                @click="navigateToNextPage()"
                color="#004DA9"
                :class="
                  $vuetify.breakpoint.name == 'xs'
                    ? 'bookBTn'
                    : 'bookBTn2' || $vuetify.breakpoint.name == 'sm'
                    ? 'bookBTn'
                    : 'bookBTn2'
                "
                outlined
                dark
                rounded
                :small="
                  $vuetify.breakpoint.name == 'xs'
                    ? true
                    : false || $vuetify.breakpoint.name == 'sm'
                    ? true
                    : false
                "
              >
                Get In Touch
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-app-bar>

    <v-navigation-drawer
      class="hiddensm-and-up"
      floating
      width="100%"
      height="100vh"
      fixed
      style="z-index: 1000"
      v-model="drawer"
    >
      <v-layout wrap style="background-color: grey">
        <v-flex xs12 class="text">
          <v-list density="compact" nav>
            <a href="/">
              <v-list-item prepend-icon="" title="Home" value="home">
                <v-icon> mdi-view-dashboard </v-icon> &nbsp; Home
              </v-list-item>
            </a>
            <a href="/#/aboutus">
              <v-list-item prepend-icon="" title="Home" value="home">
                <v-icon> mdi-home-city </v-icon> &nbsp; About Us
              </v-list-item>
            </a>
            <a href="/#/products">
              <v-list-item prepend-icon="" title="Home" value="home">
                <v-icon> mdi-home-city </v-icon> &nbsp; Product
              </v-list-item>
            </a>
            <v-list-item prepend-icon="" title="Home" value="home">
              <v-icon> mdi-file </v-icon> &nbsp; Prices
            </v-list-item>
          </v-list>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  props: ["color"],
  data() {
    return {
      drawer: false,
      items: [
        {
          title: "Foo",
          value: "foo",
        },
        {
          title: "Bar",
          value: "bar",
        },
        {
          title: "Fizz",
          value: "fizz",
        },
        {
          title: "Buzz",
          value: "buzz",
        },
      ],
      scroll_to: false,
    };
  },
  mounted() {},
  methods: {
    goto(val) {
      // this.$router.go();
      this.$router.push(val);
    },

    navigateToNextPage() {
      // console.log("44",this.$route.fullPath )
      if (this.$route.path != "/aboutus") {
        this.$router.push({ path: "/aboutus#contact" });
      } else {
        //  this.$router.push({ path: "/aboutus#contact" });
        this.scroll_to = !this.scroll_to;
        this.$emit("stepper", {
          scroll_to: this.scroll_to,
        });
      }
    },
  },
};
</script>
<style scoped>
.fx {
  position: fixed;
}
.content {
  font-size: 16px;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}

.content:hover {
  font-size: 20px;
  /* font-weight:600; */
  transition: 0.3s;
  color: #004da9;
}
</style>