<template>
  <div>
   
    <v-layout wrap justify-center pt-6 pt-sm-0  py-3 class="ftColor">
      <v-flex sm10>
        <v-layout wrap justify-center>
          <v-flex xs12 sm10 md8>
            <v-layout justify-center wrap pt-8 class="hidden-sm-and-up">
              <v-flex xs4 pl-3>
                <v-layout wrap justify-space-around text-left class="text">
                  <v-flex xs12 sm6 align-self-center>
                    <router-link :to="'/products'"> Products </router-link>
                  </v-flex>
                  <v-flex sm6 align-self-center>
                    <router-link :to="'/demobooking'"> Onboarding </router-link>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4 align-self-center>
                <router-link to="/">
                  <v-img
                    height="30px"
                    class="ml-0"
                    contain
                    src="../../assets/Images/logo.png"
                  >
                  </v-img>
                </router-link>
              </v-flex>

              <v-flex xs4 pr-3>
                <v-layout wrap justify-space-around text-right class="text">
                  <v-flex xs12 sm6 align-self-center>
                    <span @click="navigateToNextPage2()">About Us </span>
                  </v-flex>

                  <v-flex align-self-center sm6>
                    <span style="cursor: pointer" @click="navigateToNextPage()"
                      >Contact us
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 lg8 pt-sm-10 pt-md-12 pb-8 pb-sm-0 >
            <v-layout wrap  justify-space-around>
              <v-flex xs12 pb-2 class="hidden-xs-only">
                <v-layout
                  wrap
                  justify-space-around
                  text-center
               
                >
                  <v-flex xs12 sm5 md4  align-self-center >
                    <v-layout
                      wrap
                      justify-space-around
                      text-center
                      class="text"
                    >
                      <v-flex align-self-center shrink>
                        <router-link :to="'/products'"> Products</router-link>
                      </v-flex>
                      <v-flex align-self-center shrink>
                        <router-link :to="'/demobooking'">
                         Onboarding 
                        </router-link>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs2 lg1 >
                    <router-link to="/">
                      <v-img
                        height="30px"
                        class="ml-0"
                        contain
                        src="../../assets/Images/logo.png"
                      >
                      </v-img>
                    </router-link>
                  </v-flex>
                  <v-flex xs12 sm5 md4 align-self-center  >
                    <v-layout
                      wrap
                      justify-space-around
                      text-centerkk
                      class="text"
                    >
                      <v-flex align-self-center shrink>
                        <router-link :to="'/aboutus'"> About Us </router-link>
                      </v-flex>
                      <!-- <v-flex align-self-center xs4> Career </v-flex> -->
                      <v-flex align-self-center shrink>
                        <!-- <a href="/#/aboutus"> Contact us </a>
                     -->
                        <span
                          style="cursor: pointer"
                          @click="navigateToNextPage()"
                          >Contact us
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12 pt-5 >
                <v-layout wrap justify-center>
                  <v-flex xs12 md3 lg3>
                    <v-layout
                      wrap
                      justify-space-around
                      justify-center
                      text-center
                    >
                      <v-flex shrink>
                        <a
                          href="mailto:customercare@slots.com.kw"
                          target="blank"
                        >
                          <v-icon> mdi-email </v-icon>
                        </a>
                      </v-flex>
                      <v-flex shrink>
                        <a
                          href="https://www.instagram.com/slotsco/?igshid=MzRlODBiNWFlZA%3D%3D"
                          target="blank"
                        >
                          <v-icon> mdi-instagram </v-icon>
                        </a>
                      </v-flex>
                      <v-flex shrink>
                        <a
                          href="https://www.linkedin.com/company/slotskw/"
                          target="blank"
                        >
                          <v-icon> mdi-linkedin </v-icon>
                        </a>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex pl-sm-0 xs12 text-center pt-6 pb-lg-10 class="text">
                © 2024, All Rights Reserved
              </v-flex>

              <v-flex
                pr-2
                pr-sm-0
                xs10
                sm5
                text-right
                class="text hidden-xs-only"
              >
                <!-- Call us at: +965 000 000 -->
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  methods: {
    navigateToNextPage2() {
      if (this.$route.path != "/aboutus") {
        this.$router.push({ path: "/aboutus" });
      } else {
        this.$nextTick(() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        });
      }
    },

    navigateToNextPage() {
      // console.log("44",this.$route.fullPath )
      if (this.$route.path != "/aboutus") {
        this.$router.push({ path: "/aboutus#contact" });
        // this.$nextTick(() => {
        //   window.scrollTo({
        //     top: document.documentElement.scrollHeight,
        //     behavior: "smooth",
        //   });
        // });
      }
      //  else{
      //    this.$nextTick(() => {
      //     window.scrollTo({
      //       top: 0,
      //       behavior: "smooth",
      //     });
      //   });

      // }
    },
  },
};
</script>