import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

function lazyLoad(view) {
  return () => import(`@/components/${view}.vue`)
}
let router = new Router({
  mode: "history",
  // base: process.env.BASE_URL,
  base: '/',
  routes: [
    {
      path: "/",
      name: 'Home',
      props: true,
      // component: () => import("./components/Home/HomE"),
      component: lazyLoad('Home/HomE'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/aboutus",
      name: 'About Us',
      props: true,
      // component: () => import("./components/AboutUs/AboutUs"),
      component: lazyLoad('AboutUs/AboutUs'),
      meta: {
        requiresAuth: false,
        disAllow: true,
      },
    },
    {
      path: "/aboutus",
      name: 'About Us',
      props: true,
      // component: () => import("./components/AboutUs/AboutUs"),
      component: lazyLoad('AboutUs/AboutUs'),
      meta: {
        requiresAuth: false,
        disAllow: true,
      },
    },

    {
      path: "/products",
      name: 'Products',
      props: true,
      // component: () => import("./components/Products/ProductS"),
      component: lazyLoad('Products/ProductS'),
      meta: {
        requiresAuth: false,

      },
    },
    {
      path: "/demobooking",
      name: 'Demo Booking',
      props: true,
      component: lazyLoad('BookADemo/BookaDemo'),
      meta: {
        requiresAuth: false,

      },
    },
    {
      path: "/Prices",
      name: 'Prices',
      props: true,
      component: lazyLoad('Prices/HomE'),
      meta: {
        requiresAuth: false,
      },
    },
  ],

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      const element = document.getElementById(to.hash.slice(1))
      if (element) {
        return { el: element, behavior: 'smooth' }
      }
    }
    else {
      return { x: 0, y: 0 }
    }
  }


  // scrollBehavior() {
  //   document.body.scrollTop = 0; // For Safari
  //   document.documentElement.scrollTop = 0;
  // },


});

router.beforeEach((to, from, next) => {

  document.title = to.meta.title || "Slots | " + to.name;
  next();

})


export default router;
